<template>
  <div id="app">
    <component :is="layout">
      <div class="breadcrumbs-row">
        <BreadCrumbs
          :separator="true"
          :crumbs_names="rename_crumbs"
          :disabled="disabled"
        />
      </div>
      <router-view />
    </component>
  </div>
</template>

<script>
const default_layout = "default";
export default {
  props: {
    source: { type: String, default: "" },
  },
  data() {
    return {
      rename_crumbs: [
        {
          name: "Home",
          new_name: null,
          icon: this.$icons.home2,
          style: [{ name: "color", value: "#dee3ed" }],
        },
      ],
      disabled: [
        "/config/user_roles",
      ],
    };
  },
  computed: {
    layout() {
      return `${this.$route.meta.layout || default_layout}-layout`;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$watch(
        () => this.$root.loading_status,
        () => {
          const CAPACITIES_LOADED = 2;
          if (this.$root.loading_status === CAPACITIES_LOADED) {
            this.$usher._load_preferences();
          }
        },
        { immediate: true },
      );
    });
  },
};
</script>
