export default [
  {
    path: "/warehouse/kits",
    name: "kit_listing",
    component: () => import("@/views/general/kit_listing.vue"),
    meta: {
      menu_name: "Kits",
      icon: "mdi-package-variant",
      menu_section: "warehouse",
      required_capacities: ["sample"],
    },
  },
];
