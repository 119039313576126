import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [];

const routes_context = require.context("./routes", true, /.+\.js/);
for (const context_key of routes_context.keys()) {
  const route_module_payload = routes_context(context_key).default;
  if (Array.isArray(route_module_payload)) {
    routes.push(...route_module_payload);
  } else {
    routes.push(route_module_payload);
  }
}

routes.push({
  path: "*",
  name: "404",
  meta: { layout: "minimal" },
  component: () => import("@/views/404.vue"),
});

const router = new VueRouter({
  mode: "history",
  // base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  let user = localStorage.getItem("user");
  if (!user && !to.matched.some((r) => r.meta.public_access)) {
    next("/login");
    return;
  }
  user = JSON.parse(user);
  if (to.meta) {
    if (to.meta.required_capacities) {
      for (let i = 0; i < to.meta.required_capacities.length; i++) {
        const capacity = to.meta.required_capacities[i];
        if (
          !(capacity in user.capacities.queries)
          && !(capacity in user.capacities.mutations)
        ) {
          next("/403");
          return;
        }
      }
    }
    if (to.meta.required_role && user.roles.includes(to.meta.required_role)) {
      next("/403");
      return;
    }
    if (to.meta.admin_required && !user.roles.includes("superuser")) {
      next("/403");
      return;
    }
  }
  next();
});

router.afterEach((to) => {
  if (to.path === "/login") return;
  const user_item = localStorage.getItem("user");
  if (!user_item) return;
  const { data: { username } } = JSON.parse(user_item);
  const last_path_item_name = `${username}-last-path`;
  localStorage.setItem(last_path_item_name, to.path);
});

export default router;
