<template>
  <div
    v-if="toasts.length > 0"
    class="toasters-container"
  >
    <v-snackbar
      v-for="toast, i in toasts"
      :key="i"
      v-model="toast.show"
      :color="toast.type"
      :light="true"
      :timeout="toast.timeout"
      transition="toggle-slide-x-transition"
      class="snackbar"
      @input="remove_toast(toast, 200)"
    >
      <div
        v-if="Array.isArray(toast.content)"
      >
        <div
          v-for="msg, ind in toast.content"
          :key="ind"
          :class="ind === toast.content.length - 1 ? '' : 'toaster-msg'"
        >
          {{ msg }}
        </div>
      </div>
      <div
        v-if="!Array.isArray(toast.content)"
      >
        {{ toast.content }}
      </div>
      <template #action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="remove_toast(toast, -1)"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script type="text/javascript">

export default {
  name: "Toaster",
  props: {
    timeout: {
      type: Number,
      default: 3000,
    },
  },
  data() {
    return {
      toasts: [],
    };
  },
  mounted() {
    this.$root.$on("toast", (msg, type, display) => {
      this.add_toast(type || "success", msg, display);
    });
    this.$root.$on("toast_warn", (msg, display) => {
      this.add_toast("warning", msg, display);
    });
    this.$root.$on("toast_error", (e, display) => {
      console.warn(e);
      this.add_toast("error", e, display);
    });
  },
  methods: {
    add_toast(toast_type, toast_msg, toast_display) {
      const current_timeout = this.toasts.length > 0 ? this.toasts.at(-1).timeout + 1000 : this.timeout;
      this.toasts.push({
        show: true,
        type: toast_type,
        content: toast_msg.networkError
          ? "Connection error. Please check your network. Your changes might not have been saved."
          : toast_msg,
        timeout: toast_display === "permanent" ? -1 : current_timeout,
      });
    },
    remove_toast(toast_in, timeout) {
      setTimeout(() => {
        this.toasts = [...this.toasts].filter((rm_toast) => rm_toast !== toast_in);
      }, timeout);
    },
  },
};
</script>
<style scoped>
.toaster-msg {
  margin-bottom: 10px;
}
.toasters-container {
  position: absolute;
  top: 0;
  z-index: 1000;
  right: 0;
}
.v-snack {
  height: auto;
  justify-content: right;
  position: relative;
}
.v-snack.snackbar.v-snack--active.v-snack--has-background > .v-snack__wrapper > .v-snack__content {
  color: #eaffed;
}
</style>
