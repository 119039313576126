/*= ========================================================================================
  Section name: Config
  Inner routes: Housing Types, Vessel Types, Operation Types, Workflow Profiles.
========================================================================================== */

export default [
  {
    path: "/config/housing-types",
    name: "housing_types",
    meta: {
      menu_name: "Housing types",
      menu_section: "config",
      icon: "mdi-home-edit-outline",
      required_capacities: ["housing_type"],
    },
    component: () => import("@/views/config/housing_types.vue"),
  },
  {
    path: "/config/vessel-types",
    name: "vessel_types",
    meta: {
      menu_name: "Vessel types",
      menu_section: "config",
      icon: "mdi-test-tube-empty",
      required_capacities: ["vessel_type"],
    },
    component: () => import("@/views/config/vessel_types.vue"),
  },
  {
    path: "/config/operation-types",
    name: "operation_types",
    meta: {
      menu_name: "Operation types",
      menu_section: "config",
      icon: "mdi-lightning-bolt-outline",
      required_capacities: ["operation_type"],
    },
    component: () => import("@/views/config/operation_types.vue"),
  },
  // {
  //   path: "/config/workflow-profiles",
  //   name: "workflow_profiles",
  //   meta: {
  //     menu_name: "Workflow profiles",
  //     menu_section: "config",
  //     icon: "mdi-arrow-decision-outline",
  //     required_capacities: ["workflow_profile"],
  //   },
  //   component: () => import("@/views/config/workflow_profiles.vue"),
  // },
];
