/**
 * @param {Date} date
 * @param {Number} number
 * @param {"minutes" | "hours" | "days" | "weeks"} unit
 * @returns {Date} new date
 */
export function add_to_date(date, number, unit) {
  if (number && unit) {
    let milli_seconds_addition = 1000 * Number.parseInt(number, 10);
    switch (unit.toLowerCase()) {
      case "minutes":
        milli_seconds_addition *= 60;
        break;
      case "hours":
        milli_seconds_addition *= 3600;
        break;
      case "days":
        milli_seconds_addition *= 3600 * 24;
        break;
      case "weeks":
        milli_seconds_addition *= 3600 * 24 * 7;
        break;
      default:
        break;
    }
    return new Date(date.getTime() + milli_seconds_addition);
  }
  return date;
}

/**
 * Formats @date object to 'YYYY-MM-DD"T"HH24:MI:SS.MSTZH:TZM' format
 * or create Date object and then format if @date type is string
 * @date type of string must be ISO format
 * @param {object, String} date
 * @returns {string}
 */
export function toUTCzone(date) {
  if (typeof date === "string") {
    return `${new Date(date).toISOString().slice(0, -1)}+00:00`;
  }
  return `${date.toISOString().slice(0, -1)}+00:00`;
}

function concat_leading_zeros(origin, number_of_zeros) {
  if (typeof origin !== "string") {
    origin += ""; // eslint-disable-line no-param-reassign
  }
  if (origin.length === 1) {
    let res = "";
    for (let i = 0; i < number_of_zeros; i++) {
      res = `0${res}`;
    }
    return res + origin;
  }
  return origin;
}

/**
 * Formats passed date by replacing characters in
 * the passed format {@link format} parameter
 *
 * defaults to
 *  2022-01-27T10:40:07.057373+00:00
 * @param {Date} date
 * @param {String} format
 * @returns
 */
export function format_date(date, format = "DD.MM.YYYY") {
  if (!(date instanceof Date)) return date;

  if (format) {
    let date_string = format;
    if (date_string.includes("hh")) {
      const hours = concat_leading_zeros(date.getHours(), 1);
      date_string = date_string.replace("hh", hours);
    }
    if (date_string.includes("mm")) {
      const minutes = concat_leading_zeros(date.getMinutes(), 1);
      date_string = date_string.replace("mm", minutes);
    }
    if (date_string.includes("ss")) {
      const seconds = concat_leading_zeros(date.getSeconds(), 1);
      date_string = date_string.replace("ss", seconds);
    }
    if (date_string.includes("DD")) {
      const days = concat_leading_zeros(date.getDate(), 1);
      date_string = date_string.replace("DD", days);
    }
    if (date_string.indexOf("MM" !== -1)) {
      const months = concat_leading_zeros(date.getMonth() + 1, 1);
      date_string = date_string.replace("MM", months);
    }
    if (date_string.indexOf("YYYY" !== -1)) {
      date_string = date_string.replace("YYYY", date.getFullYear());
    }
    if (date_string.indexOf("YY" !== -1)) {
      date_string = date_string.replace("YY", date.getFullYear() % 1000);
    }
    return date_string;
  }
  return `${date.getUTCFullYear()}-${date.getUTCMonth() + 1
  }-${date.getUTCDate()}T${date.getUTCHours()}:${date.getMinutes()}:${date.getSeconds()}.${date.getMilliseconds()}${date.getTimezoneOffset()}`;
}
