/*= ========================================================================================
  Section name: Tasks
  Inner routes: ht Tasks
========================================================================================== */
export default [
  {
    path: "/tasks",
    name: "tasks",
    component: () => import("@/views/general/task/tasks.vue"),
    meta: {
      required_capacities: ["task"],
      menu_name: "Tasks",
      menu_section: "general",
      icon: "mdi-flag-triangle",
    },
  },
  {
    path: "/task/:id(\\d+)/:clone?",
    name: "task",
    component: () => import("@/views/general/task/task.vue"),
    meta: {
      required_capacities: ["task"],
    },
    props: (route) => {
      let id = Number.parseInt(route.params.id, 10);
      if (Number.isNaN(id)) {
        id = 0;
      }
      return { id, clone: route.params.clone };
    },
  },
];
