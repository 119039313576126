<template>
  <v-list-group
    v-if="routes.length > 0"
    ref="group"
    :prepend-icon="group_obj.icon"
    :sub-group="sub_group"
    :data-level="level"
    :link="group_obj.title === 'Home'"
    :to="group_obj.title === 'Home' ? { path: '/' } : null"
  >
    <template #activator>
      <v-list-item-content>
        <v-list-item-title>
          {{ group_obj.title }}
        </v-list-item-title>
      </v-list-item-content>
    </template>
    <template #appendIcon>
      <v-icon>mdi-chevron-down</v-icon>
    </template>
    <!-- show all foldable entries (i.e. those in "children" field of the current group_obj) -->
    <AppDrawerLeftNode
      v-for="nested_group in Object.keys(group_obj.children || [])"
      :key="nested_group"
      :grp="nested_group"
      :group_obj="group_obj.children[nested_group]"
      :sub_group="true"
      :level="parseInt(level) + 1"
    />
    <!-- show this group's routes as list-items -->
    <v-list-item
      v-for="route in routes"
      :key="route.meta.path ? route.meta.path : route.path"
      link
      :to="route.meta.path ? route.meta.path : route.path"
    >
      <v-list-item-icon v-if="route.meta && route.meta.icon">
        <v-icon color="info">
          {{ route.meta.icon }}
        </v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{ route.meta.menu_name }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list-group>
</template>

<script>
export default {
  name: "AppDrawerLeftNode",
  props: {
    grp: {
      type: String,
      required: true,
    },
    group_obj: {
      type: Object,
      required: true,
    },
    sub_group: {
      type: Boolean,
      required: true,
    },
    level: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      is_active: false,
    };
  },
  computed: {
    routes() {
      return this.$router.options.routes
        .filter((route) => this.show_route(route, this.grp))
        .sort((a, b) => (b.meta?.menu_weight ?? 0) - (a.meta?.menu_weight ?? 0));
    },
  },
  mounted() {
    if (this.$refs.group) {
      this.$watch(
        () => this.$refs.group.isActive,
        (val) => { this.is_active = val; },
      );
    }
  },
  methods: {
    /**
     * Checks if route should be visible within this specific group.
     * The check is based on
     *  1. The menu_section, speciifed in the route's meta
     *  2. whether current user has clearance to view the page-related capacity
     *  3. whether current user has a role required to access the page (if provided in meta)
     */
    show_route(route, grp) {
      if (
        !route.meta
        || route.meta.hide
        || !route.meta.menu_section
        || route.meta.menu_section !== grp
      ) {
        return false;
      }
      if (
        route.meta.required_capacities
        && !this.$user.can(route.meta.required_capacities)
      ) {
        return false;
      }

      if (
        route.meta?.required_role
        && !this.$user.get_roles().includes(route.meta.required_role)
      ) {
        return false;
      }

      if (route.meta.admin_required && !this.$user.is_super()) {
        return false;
      }
      return true;
    },
  },
};
</script>
