/*= ========================================================================================
  Section name: Chemistry
  Inner routes: Edit compound page
========================================================================================== */
export default [
  {
    path: "/chemistry/compound/:id(\\d+)?/:clone?",
    name: "compound",
    meta: {
      required_capacities: ["compound"],
    },
    component: () => import("@/views/general/compound/edit.vue"),
    props: (route) => {
      let idn = Number.parseInt(route.params.id, 10);
      if (Number.isNaN(idn)) {
        idn = 0;
      }
      return { id: idn, clone: route.params.clone };
    },
  },
  {
    path: "/chemistry/compounds",
    name: "compound_list",
    meta: {
      menu_name: "Compounds",
      menu_section: "chemistry",
      icon: "mdi-flask-outline",
      required_capacities: ["compound"],
    },
    component: () => import("@/views/general/compound/list.vue"),
  },
];
