/*= ========================================================================================
  Section name: High Throughput
  Inner routes: Group operation
========================================================================================== */
export default {
  path: "/ht/group_operation/:id/:tab?",
  name: "group_operation",
  component: () => import("@/views/oligos/group_operation/group_operation.vue"),
  meta: {
    required_capacities: ["group_operation"],
  },
  props: (route) => {
    let idn = Number.parseInt(route.params.id, 10);
    let housing_state_id = Number.parseInt(route.params.housing_state_id, 10);
    if (Number.isNaN(idn)) idn = 0;
    if (Number.isNaN(housing_state_id)) housing_state_id = 0;
    return { id: idn, housing_state_id };
  },
};
