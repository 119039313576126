/*= ========================================================================================
  Section name: High throughput
  Inner routes: Sample
========================================================================================== */
export default [
  {
    path: "/orders",
    name: "order_list",
    meta: {
      menu_weight: 8,
      menu_name: "Orders",
      menu_section: "oligos",
      icon: "mdi-clipboard-list-outline",
      required_capacities: ["sample", "shop_order"],
    },
    component: () => import("@/views/oligos/order/orders.vue"),
  },
  {
    path: "/order/:source/:id",
    name: "order",
    component: () => import("@/views/oligos/order/order.vue"),
    meta: { required_capacities: ["sample"] },
  },
];
