/*= ========================================================================================
  Section name: Config
  Inner routes: Import
========================================================================================== */
export default {
  path: "/config/import",
  name: "import",
  meta: {
    menu_name: "Import data",
    menu_section: "config",
    icon: "mdi-swap-vertical-bold",
    required_capacities: ["xtrn_ctv1_check_connection"],
    required_role: "superuser",
  },
  component: () => import("@/views/config/import.vue"),
};
