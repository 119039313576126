<template>
  <v-app>
    <app-heading @navbar_click="$refs.left_drawer.toggle()" />
    <app-drawer-left ref="left_drawer" />
    <v-main
      :style="is_width_display_mobile ? 'padding: 70px 0px 0px' : ''"
    >
      <v-container
        class="fill-height pt-0"
        fluid
      >
        <v-row
          align="center"
          justify="center"
          class="fill-height"
        >
          <v-col class="text-center fill-height">
            <slot />
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import AppHeading from "./app_heading";
import AppDrawerLeft from "./app_drawer_left";

export default {
  components: {
    "app-heading": AppHeading,
    "app-drawer-left": AppDrawerLeft,
  },
  data() {
    return {
      is_width_display_mobile: window.innerWidth <= 600,
    };
  },
  computed: {
    current_year() {
      return new Date().getFullYear();
    },
  },
};
</script>
