/*= ========================================================================================
  Section name: Chemistry
  Inner routes: Substances
========================================================================================== */
export default {
  path: "/chemistry/substances",
  name: "substance_list",
  meta: {
    menu_name: "Substances",
    menu_section: "chemistry",
    icon: "mdi-flask-outline",
    required_capacities: ["substance"],
  },
  component: () => import("@/views/general/substance/list.vue"),
};
