// extension for Hasura backend handing of user roles and permissions
// caches GraphQL capacities (mutations and queries) for all roles of current user
// this information is used to render interface components that are available to the current user

import gql from "graphql-tag";

const JWT_TOKEN = "apollo-token";
const USER_STORAGE_KEY = "user";
const WATCHDOG_TIMER_TICK_MS = 10_000;
const CAPACITIES_LOADING = 1;
const CAPACITIES_LOADED = 2;

export default {
  install(Vue) {
    function HasuraPlugin() {
      this._clear = function () {
        this.uid = 0;
        this.session_uuid = "";
        this.capacities = { queries: {}, mutations: {} };
        this.data = {};
        this.roles = [];
        if ("watchdog_timer" in this) {
          clearTimeout(this.watchdog_timer);
        }
        this.watchdog_timer = null;
      };

      this.load = function () {
        const userobj = JSON.parse(localStorage.getItem(USER_STORAGE_KEY));
        if (userobj) {
          this.uid = userobj.uid;
          this.session_uuid = userobj.session_uuid;
          this.capacities = userobj.capacities;
          this.roles = userobj.roles;
          this.data = userobj.data;

          if (!this.watchdog_timer) {
            this.watchdog_timer = setTimeout(() => {
              this._watchdog();
            }, WATCHDOG_TIMER_TICK_MS);
          }
          this.vm.$root.loading_status = CAPACITIES_LOADED;
        }
      };

      this.save = function () {
        if (!this.uid) {
          return;
        }
        const u = {};
        u.uid = this.uid;
        u.session_uuid = this.session_uuid;
        u.capacities = this.capacities;
        u.data = this.data;
        u.roles = this.roles;
        u.data = this.data;
        localStorage.setItem(USER_STORAGE_KEY, JSON.stringify(u));
      };

      // checks whether JWT is fresh, and refreshes if necessary
      this._watchdog = function () {
        // try to renew JWT before it expires
        const token = localStorage.getItem(JWT_TOKEN);
        if (token) {
          const token_data = JSON.parse(atob(token.split(".")[1]));
          const expires_at = new Date();
          expires_at.setTime(token_data.exp * 1000);
          const now = new Date();
          // if JWT expired, clear it to avoid error. Otherwise seamlessly replace old with new
          if (expires_at - now < 0) {
            localStorage.removeItem(JWT_TOKEN);
          }
          // if JWT will expire in two ticks, including already expired
          if (expires_at - now < 2 * WATCHDOG_TIMER_TICK_MS) {
            this.renew_jwt();
          }
        } else {
          this.renew_jwt();
        }
        this.watchdog_timer = setTimeout(() => {
          this._watchdog();
        }, WATCHDOG_TIMER_TICK_MS);
      };

      this.is_logged_in = function () {
        return !!this.uid;
      };

      this._collect_allowed_fields = function (types) {
        // filtering to leave only those types
        // that have to do with 'update' and 'insert' mutations
        for (const type of types.filter(
          (t) => !t.name.includes("_obj_rel")
            && !t.name.includes("_arr_rel")
            && (t.name.includes("_set_input")
              || t.name.includes("_insert_input")),
        )) {
          let mutation_name = "";
          // get the mutation name
          if (type.name.includes("_set_input")) {
            mutation_name = `update_${type.name.split("_set_input")[0]}`;
          }
          if (type.name.includes("_insert_input")) {
            mutation_name = `insert_${type.name.split("_insert_input")[0]}`;
          }
          // update capacities.mutations[mutation_name].fields
          // by pushing all fields from the type.fields array
          // so that the plugin can say that they are accessible
          if (this.capacities.mutations[mutation_name]) {
            const unique_permitted_fields = type.inputFields
              .map((field) => field.name)
              .filter(
                (field_name) => !this.capacities.mutations[mutation_name].fields.includes(field_name),
              );
            this.capacities.mutations[mutation_name].fields.push(
              ...unique_permitted_fields,
            );
          }
        }

        // to collect allowed fields for querries it is more logical
        // to iterate over capacities.queries keys
        for (const query_name of Object.keys(this.capacities.queries)) {
          const query_type = types.find((type) => type.name === query_name);
          if (query_type) {
            const already_inserted = this.capacities.queries[query_name].fields;
            const new_fields = query_type.fields
              .map((field) => field.name)
              .filter(
                (field_name) => !already_inserted.includes(field_name),
              );
            this.capacities.queries[query_name].fields.push(...new_fields);
          }
        }
      };

      // this function performs GraphQL introspection with the specified role, the result is
      // stored in local storage as ['muations'][MUTATION]:role, and so for queries
      this.update_role_capacities = function () {
        const promises = [];
        for (const role of this.roles) {
          // we need to introspect with every role that is listed for us.
          // There is an unclear problem with Apollo that leads to caching of response.
          // Even though cache is effectively disabled by fetchPolicy (proven by inspection
          // in js console), only one query takes place for any number of roles
          // (proven by tcpdump). This might be some caching in HTTP implementation
          // layers under Apollo, even though Cache-control is specified
          // so adding ${role} to query is a workaround to make a different query for every role
          const INTRO_QUERY = gql`
            query intro {
                        __schema {
                          ${role}_queries: queryType {
                            fields {
                              name
                            }
                          },
                          ${role}_mutations: mutationType {
                            fields  {
                              name
                            }
                          },
                          ${role}_types: types {
                            name
                            fields {
                              name
                            }
                            inputFields {
                              name
                            }
                          }
                        }
                      }
          `;
          const token = localStorage.getItem(JWT_TOKEN);
          const q_promise = this.vm.$apollo
            .query({
              query: INTRO_QUERY,
              context: {
                headers: {
                  Authorization: `Bearer ${token}`,
                  "x-hasura-role": role,
                  "Cache-Control": "no-cache, no-store, must-revalidate",
                },
              },
              fetchPolicy: "no-cache",
            })
            .then(({ data }) => {
              // process all allowed mutations and queries for the fetched role
              for (const entity of ["queries", "mutations"]) {
                for (const field of data.__schema[`${role}_${entity}`].fields) {
                  const action_name = field.name;
                  if (!this.capacities[entity][action_name]) {
                    this.capacities[entity][action_name] = {
                      roles: [],
                      fields: [],
                    };
                  }
                  this.capacities[entity][action_name].roles.push(role);
                }
              }
              // also process all types for the fetched role to know what role has access
              // to what fields within tables (select) or mutations (insert/update)
              this._collect_allowed_fields(data.__schema[`${role}_types`]);
              this.save();
            });
          promises.push(q_promise);
        }

        return Promise.allSettled(promises);
      };

      this.renew_jwt = function () {
        this.vm.$apollo
          .mutate({
            mutation: gql`
              mutation renew_jwt($uuid: String!, $uid: Int!) {
                renew_jwt(uuid: $uuid, user_id: $uid) {
                  success
                  token
                }
              }
            `,
            context: {
              headers: {
                "Cache-Control": "no-cache, no-store, must-revalidate",
              },
            },
            fetchPolicy: "no-cache",
            variables: {
              uuid: this.session_uuid,
              uid: this.uid,
            },
          })
          .then(({ data }) => {
            if (data.renew_jwt.success) {
              this.process_new_jwt(data.renew_jwt.token);
            } else {
              // this might have been expired or corrupted
              localStorage.removeItem(USER_STORAGE_KEY);
              this.redirect_to_login();
            }
          })
          .catch(() => {
            localStorage.removeItem(USER_STORAGE_KEY);
            this.redirect_to_login();
          });
      };

      this.process_new_jwt = function (token, capacities_loaded_callback = null) {
        // apollo consumes that from local storage, store JWT there
        localStorage.setItem(JWT_TOKEN, token);
        // the middle part of the token is base64 encoded JSON with data
        const token_data = JSON.parse(atob(token.split(".")[1]));
        const expires_at = new Date();
        expires_at.setTime(token_data.exp * 1000);
        this.uid = Number.parseInt(token_data.hasura["x-hasura-user-id"], 10);
        this.roles = token_data.hasura["x-hasura-allowed-roles"];
        this.capacities = { mutations: {}, queries: {} };
        this.save();
        this.vm.$root.loading_status = CAPACITIES_LOADING;
        this.update_role_capacities().then(() => {
          setTimeout(() => {
            this.vm.$root.loading_status = CAPACITIES_LOADED;
            if (capacities_loaded_callback) capacities_loaded_callback();
          }, 200);
        });
      };

      this.redirect_to_login = function () {
        if (this.vm.$router.currentRoute.path !== "/login") {
          this.vm.$router.push("/login");
        }
      };

      this.login = function ({ token, uuid, userdata }, capacities_loaded_callback = null) {
        this.data = JSON.parse(userdata);
        this.session_uuid = uuid;
        this.process_new_jwt(token, capacities_loaded_callback);
        if (!this.watchdog_timer) {
          this.watchdog_timer = setTimeout(() => {
            this._watchdog();
          }, WATCHDOG_TIMER_TICK_MS);
        }
      };

      this.logout = function () {
        if (!this.is_logged_in()) {
          return;
        }
        this.vm.$apollo
          .mutate({
            mutation: gql`
              mutation Logout($uuid: String!, $uid: Int!) {
                logout(uuid: $uuid, user_id: $uid) {
                  success
                }
              }
            `,
            variables: {
              uuid: this.session_uuid,
              uid: this.uid,
            },
            context: {
              headers: {
                "Cache-Control": "no-cache, no-store, must-revalidate",
              },
            },
            fetchPolicy: "no-cache",
          })
          .finally(() => {
            localStorage.removeItem(USER_STORAGE_KEY);
            localStorage.removeItem(JWT_TOKEN);
            this._clear();
            this.redirect_to_login();
          });
      };

      this.role_for = function (capacity) {
        if (capacity in this.capacities.queries) {
          return this.capacities.queries[capacity].roles[0];
        }
        if (capacity in this.capacities.mutations) {
          return this.capacities.mutations[capacity].roles[0];
        }
        return null;
      };

      this.can = function (capacities) {
        if (typeof capacities === "string") {
          return !(this.role_for(capacities) == null);
        }
        return capacities.reduce(
          (accessible, capacity) => accessible && !(this.role_for(capacity) == null),
          true,
        );
      };

      this.field_accessible = function (mutation_type, table, field) {
        if (!(`${mutation_type}_${table}` in this.capacities.mutations)) {
          return false;
        }
        return (
          this.capacities.mutations[
            `${mutation_type}_${table}`
          ].fields.includes(field)
        );
      };
      /**
       * Deletes fields from @payload object if they're not present on the schema for the current user.
       * Some of the objects include related entries from differebt DB tables, so this function is called recursively.
       *
       * !Note:
       * For the sake of not duplicating data, the introspection does NOT hold accessible fields for mutation types
       * with "_one" (insert-type) and "_by_pk" (update-type) suffixes, since the fields are the same
       * (e.g.for both "insert_equipment_one" and "insert_equipment" there's the same selection of fields of the equipment table)
       * @param {String} type - either "insert" or "update"
       * @param {String} capacity - the capacity (i.e. table) name (e.g. "equipment_state_change")
       * @param {Object} payload - mutation payload object to be trimmed
       * @param {Array} json_fields - object-type fields that are not relations
       * @param {Object} relation_dict - dictionary that maps related fields to capacity names
       *  (e.g. if an insert for group operation also inserts some housing_states - the checker needs to be told
       *    that inserting 'housing_states_out' alongside group_operaion requires access to the 'insert_housing_state' capacity)
       */
      this.check_mutation_field_access = function (
        type,
        capacity,
        payload,
        json_fields = ["meta"],
        relation_dict = {},
      ) {
        const is_array = (x) => Object.prototype.toString.call(x) === "[object Array]";

        const is_object = (x) => Object.prototype.toString.call(x) === "[object Object]";

        // if the guy is an admin, bypass this function
        if (this.roles.includes("superuser")) return payload;
        for (const field_name of Object.keys(payload)) {
          // regardless of whether the current field is a relation object/array
          // first, it is validated against the introspection
          if (!this.field_accessible(type, capacity, field_name)) {
            delete payload[field_name]; // eslint-disable-line no-param-reassign
            continue;
          }
          if (payload[field_name] === null) continue;
          // now we're checking if the current field is supposed
          // to point to entry/entries in a related table
          // if so, validate related objects too (hello Mr. Recursion)
          if (!json_fields.includes(field_name)) {
            if (is_array(payload[field_name].data)) {
              for (const related_array_element of payload[field_name].data) {
                this.check_mutation_field_access(
                  type,
                  relation_dict[field_name],
                  related_array_element,
                );
              }
            }
            if (is_object(payload[field_name].data)) {
              this.check_mutation_field_access(
                type,
                relation_dict[field_name],
                payload[field_name].data,
              );
            }
          }
        }
      };

      this.get_permitted_fields = function (action) {
        // this trimming is safe since field permisions for
        // insert_<> and insert_<>_one mutations are the same
        // the same applies to update_<> and update_<>_by_pk
        const trimmed_action = action.replaceAll("_by_pk", "").replaceAll("_one");

        if (this.capacities.mutations[trimmed_action]) {
          return this.capacities.mutations[trimmed_action].fields;
        }
        return this.capacities.queries[trimmed_action].fields;
      };

      this.get_roles = function () {
        return this.roles;
      };

      this.is_super = function () {
        return this.roles.includes("superuser");
      };

      // this is a singleton
      this.get = function (vm) {
        if (HasuraPlugin.instance) {
          return HasuraPlugin.instance;
        }
        this.vm = vm;
        this._clear();
        this.load();
        HasuraPlugin.instance = this;
        return HasuraPlugin.instance;
      };
    }

    Vue.mixin({
      // 'this' inside this additional hook-logic
      // is the vm of literally any component in our app
      // so the HasuraPlugin() singleton will only get instantiated
      // and added to the $root of the application, if it is the App component itself
      // that runs this mixin hook
      beforeMount() {
        if (this.$parent === undefined) {
          this.$root.loading_status = 0;
          Vue.prototype.$user = new HasuraPlugin().get(this); // eslint-disable-line no-param-reassign
        }
      },
    });
  },
};
