export default [
  {
    path: "/ht/housings",
    name: "housing_list",
    meta: {
      menu_name: "Housings",
      menu_section: "oligos",
      icon: "mdi-home-search-outline",
      required_capacities: ["housing", "housing_type"],
      menu_weight: 2,
    },
    component: () => import("@/views/oligos/housing/housings.vue"),
  },
  {
    path: "/ht/housing/:id",
    name: "housing",
    component: () => import("@/views/oligos/housing/housing_view.vue"),
    meta: { required_capacities: ["housing", "housing_type"] },
    props: (route) => {
      let idn = Number.parseInt(route.params.id, 10);
      let hs_id = Number.parseInt(route.params.housing_state_id, 10);
      if (Number.isNaN(idn)) idn = 0;
      if (Number.isNaN(hs_id)) hs_id = 0;
      return { id: idn, housing_state_id: hs_id };
    },
  },
];
