/*= ========================================================================================
  Section name: Chemistry
  Inner routes: Experiment
========================================================================================== */
export default [
  {
    path: "/chemistry/experiments",
    name: "experiment_listing",
    component: () => import("@/views/general/experiment/experiment_listing.vue"),
    meta: {
      // TOOD: remove hide-flag
      // when chemistry is ready for production
      hide: false,
      menu_name: "Experiments",
      icon: "mdi-notebook-edit-outline",
      menu_section: "chemistry",
      required_capacities: ["group_operation", "protocol"],
    },
  },
  {
    path: "/chemistry/experiment/:id/:tab?",
    name: "experiment",
    component: () => import("@/views/general/experiment/experiment.vue"),
    props: (route) => {
      let id = Number.parseInt(route.params.id, 10);
      if (Number.isNaN(id)) {
        id = 0;
      }
      return { id };
    },
  },
];
