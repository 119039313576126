export function base64_to_array_buffer(base64) {
  const binary_string = window.atob(base64);
  const len = binary_string.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binary_string.codePointAt(i);
  }
  return bytes.buffer;
}
/**
 * @typedef {import('frontend/src/types/General.d.js').QuantitativeProperty} QuantitativeProperty
 */
/**
 * Representing the number to the specified precision.
 * @param {Number} num - the value to be trimmed
 * @param {Number} preсision - is the number of significant digits. Default value is 3. Should be between 1 and 100 (inclusive).
 * @returns trimmed number
 */
export const format_num = (num, precision = 3) => {
  if (!num) return num;
  // toPrecision returns a string and utilizes exponential notation like '1.123e+7'.
  // so parseFloat is needed
  return Number.parseFloat((num).toPrecision(precision));
};

export const metric_prefixes = {
  k: 3,
  M: 6,
  G: 9,
  m: -3,
  µ: -6,
  n: -9,
  p: -12,
};
export const metric_units = ["m", "mol", "AU"];
export const units_to_degree = {
  volume: {
    uL: 0,
    mL: 3,
    L: 6,
  },
  mass: {
    pg: -9,
    ng: -6,
    ug: -3,
    mg: 0,
    g: 3,
    kg: 6,
  },
  amount: {
    nmol: 0,
    umol: 3,
    mmol: 6,
    mol: 9,
  },
  count: {
    pcs: 0,
  },
  atomic_mass: {
    Da: 0,
    kDa: 3,
    MDa: 6,
  },
  concentration: {
    nM: -9,
    uM: -6,
    mM: -3,
    M: 0,
  },
  molar_mass: {
    "g/mol": 0,
  },
  unit: {
    unit: 0,
  },
  density: {
    "g/L": 0,
  },
  mass_fraction: {
    "-": 0,
    "%": -2,
  },
};
/**
 * Transform object units_to_degree so that
 * degrees are keys, and units a values
 */
export const degrees_to_units = (() => {
  const res = {};
  for (const [property, value] of Object.entries(units_to_degree)) {
    res[property] = {};
    for (const [deg_str, deg] of Object.entries(value)) {
      res[property][deg] = deg_str;
    }
  }
  return res;
})();

/**
 * Converts passed value to a 3-digit form with the appropriate dimensional suffix.
 * @param {Number} value - in standard units (mass - mg, volume - uL, amount - nmol)
 * @param {QuantitativeProperty} property
 * @returns
 */
export const prettify = (value, property) => {
  const max_pow_available = Math.max(...Object.values(units_to_degree[property]));
  const min_pow_available = Math.min(...Object.values(units_to_degree[property]));
  let pow = Math.floor(Math.log10(value) / 3) * 3;
  pow = pow < min_pow_available
    ? min_pow_available
    : (pow > max_pow_available
      ? max_pow_available
      : pow);
  const target_pow_string = degrees_to_units[property][`${pow}`];
  const target_pow = units_to_degree[property][target_pow_string];

  if (property) {
    const target_degree_val = value / 10 ** (target_pow);
    return `${format_num(target_degree_val)} ${target_pow_string}`;
  }
  return "unknown measurement unit";
};

/**
 * signed_prettify = prettify with negative value support
 * @param {Number} value (see 'prettify' description above)
 * @param {String} property (see 'prettify' description above)
 * @returns
 */
export const signed_prettify = (value, property) => {
  if (!property) return "unknown measurement unit";
  const sign = Math.sign(value);
  return `${sign < 0 ? "-" : ""}${prettify(sign * value, property)}`;
};

/**
 *
 * @param {String} property - "mass"/"volume"
 * @param {Number} value - number to be converted to a different degree of 10
 * @param {String} value_unit - initial degree of 10 (mL, mg)
 * @returns
 */
export const trim_number_degree = (property, value, value_unit) => {
  if (!property || !(property in units_to_degree)) {
    return "unknown measurement unit";
  }
  const pow = units_to_degree[property][value_unit];
  if (pow === undefined) {
    return `Unknown unit: "${value_unit}"`;
  }
  const norm_val = value * 10 ** pow;
  return norm_val;
};

/**
 * Turns decimal minutes (e.g. 10.988 min) into clock-like timestamp
 * @param {Number} minutes
 * @returns trimmed string
 */
export const decimal_min_to_ss = (minutes) => {
  const min = Math.floor(Math.abs(minutes));
  const sec = Math.floor((Math.abs(minutes) * 60) % 60);
  return `${(min < 10 ? "0" : "")}${min}:${(sec < 10 ? "0" : "")}${sec}`;
};

export const transliterate = (word) => {
  const a = {
    Ё: "YO", Й: "I", Ц: "TS", У: "U", К: "K", Е: "E", Н: "N", Г: "G", Ш: "SH", Щ: "SCH", З: "Z", Х: "H", Ъ: "'", ё: "yo", й: "i", ц: "ts", у: "u", к: "k", е: "e", н: "n", г: "g", ш: "sh", щ: "sch", з: "z", х: "h", ъ: "'", Ф: "F", Ы: "I", В: "V", А: "A", П: "P", Р: "R", О: "O", Л: "L", Д: "D", Ж: "ZH", Э: "E", ф: "f", ы: "i", в: "v", а: "a", п: "p", р: "r", о: "o", л: "l", д: "d", ж: "zh", э: "e", Я: "Ya", Ч: "CH", С: "S", М: "M", И: "I", Т: "T", Ь: "'", Б: "B", Ю: "YU", я: "ya", ч: "ch", с: "s", м: "m", и: "i", т: "t", ь: "'", б: "b", ю: "yu",
  };
  return [...word].map((char) => a[char] || char).join("");
};

export const to_superscript = (digit) => ({
  "-": "⁻",
  0: "⁰",
  1: "¹",
  2: "²",
  3: "³",
  4: "⁴",
  5: "⁵",
  6: "⁶",
  7: "⁷",
  8: "⁸",
  9: "⁹",
})[`${digit}`] || "";

// the value's units (degrees) in wich units are stored in db (aka base)
export const units_base = {
  volume: "uL",
  mass: "mg",
  amount: "nmol",
  atomic_mass: "Da",
  molar_concentration: "M",
  molar_mass: "g/mol",
  unit: "unit",
  density: "g/L",
  concentration: "M",
  mass_fraction: "-",
  count: "pcs",
  au: "au",
};

const mod_to_base_unit = {
  volume: "uL",
  mass: "mg",
  amount: "nmol",
  count: "pcs",
  au: "au",
};
/**
 * Converts mod to units
 * @param {QuantitativeProperty} unit_type - the key of units_to_degree
 * @returns {String} - converted value
 */
export const convert_mod_to_base_unit = (mod) => {
  if (mod in mod_to_base_unit) {
    return mod_to_base_unit[mod];
  }
  return null;
};

/**
 * Converts passed mod value into the DB-stored degree/format
 * @param {string} mod - a key from {@link units_base}
 * @returns {string} = a value from {@link units_base}
 */
export const get_base_unit_of_mod = (mod) => {
  if (!units_base.hasOwnProperty(mod)) {
    throw new Error(`get_base_unit_of_mod: can't find base unit for ${mod}`);
  }
  return units_base[mod];
};

const mod_by_unit = (() => {
  const res = {};
  for (const [property, value] of Object.entries(units_to_degree)) {
    for (const deg_str of Object.keys(value)) {
      if (res.hasOwnProperty(deg_str)) {
        res[deg_str] = [res[deg_str], property].flat();
      } else {
        res[deg_str] = property;
      }
    }
  }
  return res;
})();

/**
 * Converts value from units to base units or from base to unit if to_unit=true
 * @param {Number} value - number to be converted
 * @param {String} unit - the units of the value
 * @param {QuantitativeProperty} unit_type - the key of units_to_degree
 * @param {Boolean} to_unit - if false -> the {@link value} is converted from {@link unit} to base, if true -> the {@link value} is converted from base to {@link unit}. Default value is false.
 * @returns {Number} - converted value
 */
export const convert_to_base_unit = (value, unit, unit_type, to_unit = false) => {
  const unit_deg = units_to_degree[unit_type][unit];
  const base_deg = units_to_degree[unit_type][units_base[unit_type]];

  const deg_diff = to_unit ? -(unit_deg - base_deg) : (unit_deg - base_deg);
  const res = value * 10 ** deg_diff;
  return res;
};

export const define_unit_type = (unit) => {
  if (unit in mod_by_unit) {
    return mod_by_unit[unit];
  }
  throw new Error(`src/lib/conversions.js: unknown units '${unit}'`);
};

const standarts_units = {
  volume: "L",
  mass: "g",
  amount: "mol",
  atomic_mass: "Da",
  molar_concentration: "M",
  molar_mass: "g/mol",
  unit: "unit",
  density: "g/L",
  concentration: "M",
  mass_fraction: "%",
};
/**
 * Converts given Unit to standard value (without prefix: g, mol, L etc)
 * @param {{value: number, units: string}} param
 * @returns {number}
 */
export const convert_to_standard_unit = (param) => {
  const unit_type = define_unit_type(param.units);
  const standart_units = standarts_units[unit_type];
  return convert_to_base_unit(param.value, standart_units, unit_type, true);
};

/**
 * Converts input string to string where all non-word characters replaced by "_"
 * @param {string} input
 * @returns string
 */
export const convert_to_valid_html_id = (input) => input
  .replace(/\W/g, "_")
  .toLowerCase();
