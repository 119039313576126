/*= ========================================================================================
  Section name: Config
  Inner routes: User Roles
========================================================================================== */
export default {
  path: "/config/user_roles",
  name: "user_roles",
  meta: {
    menu_name: "User roles",
    menu_section: "config",
    icon: "mdi-account-edit-outline",
    required_capacities: ["hasura_role"],
    admin_required: true,
  },
  component: () => import("@/views/config/user_roles.vue"),
};
