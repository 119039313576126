/*= ========================================================================================
  Section name: Chemistry
  Inner routes: View dataset page
========================================================================================== */
export default [
  {
    path: "/datasets",
    name: "datasets",
    meta: {
      required_capacities: ["dataset"],
      menu_section: "general",
      menu_name: "Datasets",
      icon: "mdi-database-outline",
    },
    component: () => import("@/views/general/dataset/list.vue"),
  },
  {
    path: "/dataset/:id(\\d+)?",
    name: "dataset",
    component: () => import("@/views/general/dataset/edit.vue"),
    props: (route) => {
      let idn = Number.parseInt(route.params.id, 10);
      if (Number.isNaN(idn)) {
        idn = 0;
      }
      return { dataset_id: idn };
    },
  },
];
