/*= ========================================================================================
  Section name: Administration
  Inner routes: Tags
========================================================================================== */
export default {
  path: "/tags",
  name: "tags",
  meta: {
    menu_name: "Tags",
    menu_section: "administration",
    icon: "mdi-tag-multiple",
    admin_required: false,
  },
  component: () => import("@/views/main_page/tag/tag.vue"),
};
