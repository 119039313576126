/*= ========================================================================================
  Section name: Main
  Inner routes: 404, 403, My, login, register
========================================================================================== */
export default [
  {
    path: "/",
    name: "home_page",
    component: () => import("@/views/main_page/home.vue"),
  },
  {
    path: "/login",
    name: "login",
    meta: { layout: "minimal", public_access: true },
    component: () => import("@/views/login.vue"),
  },
  {
    path: "/register",
    name: "register",
    meta: { layout: "minimal", public_access: true },
    component: () => import("@/views/register.vue"),
  },
  {
    path: "/403",
    name: "403",
    meta: { layout: "minimal" },
    component: () => import("@/views/403.vue"),
  },
];
