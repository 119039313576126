import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

const opts = {
  theme: {
    themes: {
      light: {
        primary: "#5282FF",
        accent: "#194BB9",
        secondary: "#BBC3CF",
        info: "#A8C8F7",
        success: "#28A745",
        error: "#FF2305",
        warning: "FFB800",
      },
    },
  },
};

export default new Vuetify(opts);
