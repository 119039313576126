/*= ========================================================================================
  Section name: High Throughput
  Inner routes: Group Workflow
========================================================================================== */
export default [
  {
    path: "/ht/group_workflow",
    name: "group_workflow_list",
    meta: {
      menu_weight: 9,
      path: "/ht/group_workflow",
      menu_name: "Group workflows",
      icon: "mdi-domain",
      menu_section: "oligos",
      required_capacities: ["group_workflow"],
    },
    component: () => import("@/views/oligos/group_workflow/group_workflow_list.vue"),
  },
  {
    path: "/ht/group_workflow/:id(\\d+)?/:housing_id?",
    name: "group_workflow",
    meta: {
      path: "/ht/group_workflow/",
      required_capacities: ["group_workflow"],
    },
    component: () => import("@/views/oligos/group_workflow/editor.vue"),
    props: (route) => {
      let idn = Number.parseInt(route.params.id, 10);
      if (Number.isNaN(idn)) {
        idn = 0;
      }
      return { id: idn, housing_id: route.params.housing_id };
    },
  },

];
