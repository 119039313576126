<template>
  <v-app-bar
    app
    :absolute="is_width_display_mobile"
    clipped-left
    height="70"
    class="app-header"
    elevation="2"
  >
    <v-toolbar-title class="pl-0 ml-sm-0 ml-2 logo">
      <router-link to="/">
        <v-img src="@/assets/logo.svg" />
      </router-link>
    </v-toolbar-title>
    <v-app-bar-nav-icon
      class="btn--drawer v-btn--flat order-last order-sm-0 ml-sm-16"
      @click.stop="$emit('navbar_click')"
    >
      <template #default>
        <i class="ctx-icons icon-app-bar" />
      </template>
    </v-app-bar-nav-icon>
    <!--User info-->
    <div
      class="user d-none d-sm-flex"
      @click="navigate_home"
    >
      <v-avatar
        class="user__avatar"
        min-width="40"
        min-height="40"
        width="40"
        height="40"
      >
        <img
          class="user__avatar-img"
          :src="avatar"
        >
      </v-avatar>
      <div class="user__info">
        <p class="user__name mb-0">
          {{ real_name }}
        </p>
        <div class="user__roles">
          <p class="user__role mb-0">
            {{ roles }}
          </p>
        </div>
      </div>
    </div>
    <!--User info-->
    <v-btn
      class="ml-auto ml-sm-0 btn--logout v-btn--flat d-none d-sm-block"
      icon
      @click="logout"
    >
      <i class="ctx-icons icon-logout" />
    </v-btn>
    <v-menu
      class="d-sm-none"
      min-width="230"
      bottom
      offset-y
    >
      <template #activator="{ on, attrs }">
        <v-btn
          class="ml-auto ml-sm-0 btn--profile d-sm-none"
          icon
          v-bind="attrs"
          v-on="on"
        >
          <i class="ctx-icons icon-profile" />
        </v-btn>
      </template>
      <v-list class="pt-2 pb-2">
        <v-list-item
          link
          class="d-flex d-sm-none"
        >
          <!--User info-->
          <div
            class="user d-flex ma-0"
            @click="navigate_home"
          >
            <v-avatar
              class="user__avatar"
              min-width="40"
              min-height="40"
              width="40"
              height="40"
            >
              <img
                class="user__avatar-img"
                :src="avatar"
              >
            </v-avatar>
            <div class="user__info">
              <p class="user__name mb-0">
                {{ real_name }}
              </p>
              <div class="user__roles">
                <p class="user__role mb-0">
                  {{ roles }}
                </p>
              </div>
            </div>
          </div>
          <!--User info-->
        </v-list-item>
        <v-divider class="d-block d-sm-none mb-3" />
        <v-list-item
          class="v-list-item--logout"
          dense
          @click="logout"
        >
          <i class="ctx-icons icon-logout" />
          <v-list-item-title class="text-subtitle-2">
            Logout
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <toaster />
  </v-app-bar>
</template>
<script>
// components to show toast notifications via event bus - available everywhere
import toaster from "./toaster.vue";

export default {
  components: {
    toaster,
  },
  data() {
    return {
      is_width_display_mobile: window.innerWidth <= 600,
      avatar_img: null,
    };
  },
  apollo: {
    user_by_pk: {
      query: require("@/graphql/config/user/get_user_avatar.gql"),
      variables() {
        return { user_id: this.$user.uid };
      },
      result({ data: { user_by_pk } }) {
        if (!this.user_by_pk?.avatar_img) {
          this.avatar_img = "https://avatars.mds.yandex.net/get-yapic/null/islands-150";
          return;
        }
        this.avatar_img = JSON.parse(JSON.stringify(user_by_pk.avatar_img));
      },
      skip() {
        return !this.$user.uid;
      },
    },
  },
  computed: {
    avatar() {
      if (!this.$user.is_logged_in()) {
        return "";
      }
      return this.avatar_img;
    },
    real_name() {
      if (!this.$user.is_logged_in()) {
        return "";
      }
      return this.$user.data.first_name;
    },
    roles() {
      if (!this.$user.is_logged_in()) {
        return "";
      }
      return this.$user.roles.join(", ");
    },
  },
  methods: {
    logout() {
      this.$user.logout();
    },
    navigate_home() {
      if (this.$router.history.current.path !== "/") {
        this.$router.push("/");
      }
    },
  },
};
</script>
<style>
.theme--light.v-app-bar.v-toolbar.v-sheet {
  background-color: #ffffff;
}
.flex-none {
  flex: none;
}

.v-list-item .v-list-item__title,
.v-list-item .v-list-item__subtitle {
  line-height: 1.1;
}
.v-toolbar__content {
  justify-content: space-between;
}
</style>
