/*= ========================================================================================
  Section name: Targets
  Inner routes: Synthesis Targets
========================================================================================== */
export default [
  {
    path: "/oligo/synthesis-targets",
    name: "oligo_synthesis_targets",
    meta: {
      menu_weight: 8,
      menu_name: "Oligo synthesis targets",
      menu_section: "oligos",
      icon: "mdi-flag-triangle",
      required_capacities: ["target", "group_workflow"],
    },
    component: () => import("@/views/oligos/oligo_synthesis_target/oligo_synthesis_targets.vue"),
  },
  {
    path: "/oligo/synthesis-target/:id(\\d+)?/:clone?",
    name: "target",
    component: () => import("@/views/oligos/oligo_synthesis_target/oligo_synthesis_target.vue"),
    meta: { required_capacities: ["target"] },
    props: (route) => {
      let idn = Number.parseInt(route.params.id, 10);
      if (Number.isNaN(idn)) {
        idn = null;
      }
      return { id: idn, clone: route.params.clone ?? null };
    },
  },
];
