/*= ========================================================================================
  Section name: General
  Inner routes: View/Edit protocol page
========================================================================================== */
export default [
  {
    path: "/chemistry/protocol/:id(\\d+)?/:clone?",
    name: "protocol",
    meta: {
      required_capacities: ["protocol"],
    },
    component: () => import("@/views/general/protocol/protocol.vue"),
    props: (route) => {
      let idn = Number.parseInt(route.params.id, 10);
      if (!route.params.id) return;
      if (Number.isNaN(idn)) {
        idn = 0;
      }
      return { id: idn, clone: route.params.clone };
    },
  },
  {
    path: "/chemistry/protocols",
    name: "protocol_list",
    meta: {
      // TOOD: remove hide-flag
      // when chemistry is ready for production
      hide: true,
      menu_name: "Protocols",
      menu_section: "chemistry",
      icon: "mdi-clipboard-list-outline",
      required_capacities: ["protocol"],
    },
    component: () => import("@/views/general/protocol/list.vue"),
  },
];
