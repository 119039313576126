/*= ========================================================================================
  Section name: Chemistry
  Inner routes: Sample
========================================================================================== */
export default [
  {
    path: "/samples",
    name: "sample_list",
    meta: {
      menu_name: "Samples",
      menu_section: "oligos",
      icon: "mdi-test-tube-empty",
      required_capacities: ["sample", "group_workflow"],
      menu_weight: 5,
    },
    component: () => import("@/views/general/sample/samples.vue"),
  },
  {
    path: "/sample/:id/operation",
    name: "sample:operation",
    meta: {
      required_capacities: ["sample"],
    },
    component: () => import("@/views/general/sample/sample.vue"),
  },
  {
    path: "/sample/:id(\\d+)?/:tab?",
    name: "sample",
    component: () => import("@/views/general/sample/sample.vue"),
    meta: {
      required_capacities: ["sample"],
    },
    props: (route) => {
      let idn = Number.parseInt(route.params.id, 10);
      if (Number.isNaN(idn)) {
        idn = 0;
      }
      return { id: idn };
    },
  },
];
