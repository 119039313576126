import Vue from "vue";
import { format_date } from "@/lib/date.js";
import { format_num } from "@/lib/conversions.js";
import { format_seq_info } from "@/lib/oligo_sequence.js";
import { sum_sample_states_amount } from "@/lib/sample_states.js";

export const filters = {
  format_date: (date_string, format = "DD.MM.YYYY", fallback = "-") => {
    if (!date_string || Number.isNaN(Date.parse(date_string))) return fallback;
    return format_date(new Date(date_string), format);
  },
  format_number: format_num,
  format_seq: format_seq_info,
  format_seconds: (original_seconds) => {
    const hours = `${Math.floor(original_seconds / 3600)}`;
    const minutes = `${Math.floor((original_seconds - hours * 3600) / 60)}`;
    const seconds = `${Math.ceil(original_seconds - hours * 3600 - minutes * 60)}`;

    const leading_zeros = (num_string) => (num_string.length === 2
      ? num_string
      : (num_string.length === 1
        ? `0${num_string}`
        : "00"));
    return `${hours}:${leading_zeros(minutes)}:${leading_zeros(seconds)}`;
  },
  get_total_amount: (sss) => sum_sample_states_amount(sss) || "N/a",
};

for (const [name, fn] of Object.entries(filters)) {
  Vue.filter(name, fn);
}
