/*= ========================================================================================
  Section name: Chemistry
  Inner routes: Operation
========================================================================================== */
export default [
  {
    path: "/operation/:id(\\d+)?/:tab?",
    name: "operation",
    component: () => import("@/views/general/operation/operation.vue"),
    // meta: { required_capacities: ["operation"] }, // TODO ?
    props: (route) => {
      let idn = Number.parseInt(route.params.id, 10);
      if (Number.isNaN(idn)) {
        idn = 0;
      }
      return { id: idn };
    },
  },
];
