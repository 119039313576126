/*= ========================================================================================
  Section name: High Throughput
  Inner routes: Equipment, Equipment List
========================================================================================== */
export default [
  {
    path: "/equipment-list",
    name: "equipment_list",
    component: () => import("@/views/oligos/equipment/equipment_list.vue"),
    meta: {
      menu_name: "Equipment list",
      menu_section: "oligos",
      icon: "mdi-microscope",
      required_capacities: ["equipment"],
      menu_weight: 3,
    },
  },
  {
    path: "/equipment/:id(\\d+)?/:clone?/:tab?",
    name: "equipment",
    component: () => import("@/views/oligos/equipment/equipment.vue"),
    props: (route) => {
      const { tab, id: route_param_id, clone: route_param_clone } = route.params;
      let idn = Number.parseInt(route_param_id, 10);
      if (Number.isNaN(idn)) {
        idn = 0;
      }
      return { id: idn, tab, clone: route_param_clone };
    },
  },
];
