/*= ========================================================================================
  Section name: Config
  Inner routes: Printers
========================================================================================== */
export default {
  path: "/config/printers",
  name: "printers",
  meta: {
    menu_name: "Printers",
    menu_section: "config",
    icon: "mdi-printer-outline",
    required_capacities: ["printer"],
  },
  component: () => import("@/views/config/printers.vue"),
};
