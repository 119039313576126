/*= ========================================================================================
  Section name: Tools
  Inner routes: Document template
========================================================================================== */
export default [
  {
    path: "/document_templates",
    name: "document_template_list",
    component: () => import("@/views/general/template_editor/document_template_list.vue"),
    meta: {
      menu_name: "Document templates",
      menu_section: "general",
      icon: "mdi-printer-outline",
      required_capacities: ["document_template"],
    },
  },
  {
    path: "/document_template/:id(\\d+)?/:clone?",
    name: "document_template_editor",
    meta: {
      icon: "mdi-label",
      required_capacities: ["document_template"],
    },
    component: () => import("@/views/general/template_editor/editor.vue"),
    props: (route) => {
      let idn = Number.parseInt(route.params.id, 10);
      if (Number.isNaN(idn)) {
        idn = 0;
      }
      return { id: idn, clone: route.params.clone };
    },
  },
];
