import Vue from "vue";
import VueKonva from "vue-konva";
import BreadCrumbs from "@lumiprobejs/breadcrumbs";

import iconsPlugin from "./plugins/icons";
import "./assets/styles/custom-styles.sass";
import "./assets/styles/overrides.sass";

import "@/filters/filters";

import HasuraUser from "./plugins/hasura-user";
import Usher from "./plugins/usher.js"; // eslint-disable-line import/no-cycle
import Default from "./layouts/default";
import Minimal from "./layouts/minimal";

import { createProvider } from "./vue-apollo";
import App from "./App.vue";

import router from "./router";

import "@lumiprobejs/breadcrumbs/dist/smart-filter/bread-crumbs.css";
import vuetify from "./plugins/vuetify";

Vue.component("DefaultLayout", Default);
Vue.component("MinimalLayout", Minimal);

Vue.use(HasuraUser);
Vue.use(Usher);
Vue.use(VueKonva);
Vue.use(BreadCrumbs);
Vue.use(iconsPlugin);

Vue.config.productionTip = false;
export const vm = new Vue({
  router,
  apolloProvider: createProvider(),
  vuetify,
  data: {
    loading_status: 0,
  },
  render: (h) => h(App),
}).$mount("#app");
