import set from "lodash/set";
import get from "lodash/get";
import cloneDeep from "lodash/cloneDeep";

// Trying out FP style programming with these following functions

/**
 * The method takes an object key or a path to read, a new key or path to set and a callback that modifies the value
 * that was gotten
 * @param {string|string[]} get_key
 * @param {string|string[]} set_key
 * @param {function} [transform]
 * @returns new object
 */
export const get_set = (get_key, set_key, transform = null) => (obj) => {
  const clone = cloneDeep(obj);
  set(
    clone,
    set_key,
    transform ? transform(get(clone, get_key, null)) : get(clone, get_key, null),
  );
  return clone;
};

export const add_key_as_value = get_set("key", "value", (key) => key);

export const map = (f) => (functor) => functor.map(f);
export const flatMap = (f) => (functor) => functor.flatMap(f);
export const filter = (f) => (functor) => functor.filter(f);
export const every = (f) => (functor) => functor.every(f);
export const some = (f) => (functor) => functor.some(f);
export const includes = (target) => (functor) => functor.includes(target);
export const sort = (f) => (functor) => functor.sort(f);
export const pipe = (x0, ...funcs) => funcs.reduce((x, f) => f(x), x0);
