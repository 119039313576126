export default {
  path: "/qc",
  name: "qc_batches_list",
  meta: {
    menu_weight: 8,
    menu_name: "Qc",
    menu_section: "chemistry",
    icon: "mdi-clipboard-list-outline",
    required_capacities: ["sample"],
  },
  component: () => import("@/views/qc/qc_batches.vue"),
};
