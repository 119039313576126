/*= ========================================================================================
  Section name: High Throughput
  Inner routes: Samples in progress
========================================================================================== */
export default [
  {
    path: "/samples-in-progress",
    name: "samples_in_progress",
    meta: {
      menu_weight: 10,
      menu_name: "Samples in progress",
      icon: "mdi-test-tube",
      menu_section: "oligos",
      required_capacities: ["sample", "group_workflow"],
    },
    component: () => import("@/views/oligos/samples-in-progress/samples-in-progress.vue"),
  },
];
