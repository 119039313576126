import uniq from "lodash/uniq";

export const mod_regex = /\[[\w+-]+]/g;

/**
 *
 * @param {String} sequence
 * @returns Array<String>
 */
export const get_mods_from_sequence = (sequence) => {
  if (!sequence) return [];

  return sequence.match(mod_regex) || [];
};

/**
 * This function attends to the fact that oligonucleotide sequences contain
 * mods that are monomers and take up more than 1 symbol.
 *
 * @param {String} sequence
 * returns Number
 */
export const get_sequence_length = (sequence) => {
  if (!sequence) return 0;
  const mods = sequence.match(mod_regex) || [];
  const seq_wo_mods = sequence.split(mod_regex).join("");
  return mods.length + seq_wo_mods.length;
};

/**
 * Renders a string with oligo sequence info
 * @param {Sample | Oligo | Target} item
 * @returns {string} string with oligo type, seq length and modification listing
 */
export const format_seq_info = (item) => {
  // if no oligo field then the oligo is passed to the function
  const oligo = item.compound?.compound_substances.find((cso) => !!cso.oligo)?.oligo ?? item;
  const sequence = oligo.technical_sequence ?? oligo.sequence;
  // replace mods with single letter and count oligo length to avoid server API call
  const length = oligo?.length ?? sequence?.replace(/\[(.+?)]/g, "q").length;
  const length_str = `${length}mer`;
  let mod_string = "";

  const raw_mods = sequence?.match(/\[(.+?)]/g) || [];
  if (raw_mods.length > 0) {
    const mods = uniq(raw_mods).sort();
    // show in format [ACH] and [BHQ2]; or [ACH], [FAM], [MODS1] and [MOD]; or [MODS], [MODS], .. and 5 more
    const MODS2SHOW_COUNT = 3;
    // remove square brackets from mods
    const mods2show = mods
      .slice(0, MODS2SHOW_COUNT)
      .map((v) => v.slice(1, -1));
    mod_string += `\nwith ${mods2show
      .slice(0, -1)
      .join(", ")}`;
    if (mods.length > mods2show.length) {
      mod_string += `, ${mods2show.slice(-1)}`;
      mod_string += ` and ${mods.length - mods2show.length} more mods`;
    } else {
      mod_string += (mods.length > 1 ? " and " : "") + mods2show.slice(-1);
    }
  }
  return `${oligo.type} / ${length_str} ${mod_string}`;
};
