/*= ========================================================================================
  Section name: Warehouse
  Inner routes: Packaging
========================================================================================== */
export default {
  path: "/warehouse/packagings",
  name: "packaging_page",
  component: () => import("@/views/general/packaging/packaging_page.vue"),
  meta: {
    menu_name: "Packaging",
    icon: "mdi-package-variant",
    menu_section: "warehouse",
    required_capacities: ["sample"],
  },
};
